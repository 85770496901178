<template>
  <g>
    <svg:style>
      .hiddenHandleContainer {
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
        fill: url(#BG-gradient);
      }
    </svg:style>

    <filter id="inner-shadow">
      <feFlood flood-color="rgba(0,0,0,0.80)" />
      <feComposite operator="out" in2="SourceGraphic" />
      <feMorphology operator="dilate" radius="2" />
      <feGaussianBlur stdDeviation="2" />
      <feComposite operator="atop" in2="SourceGraphic" />
    </filter>

    <g id="hidden_handle">
      <rect
        class="hiddenHandleContainer"
        :x="handleX1"
        :y="handleY1"
        :style="{ fill: isBlack ? 'black' : fill, stroke: doorStrokeColor }"
        width="8.34"
        height="222.7"
        filter="url(#inner-shadow)"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: {
    isBlack: {
      type: Boolean,
      default: false
    },
    handleX: {
      type: Number,
      default: 0
    },
    handleY: {
      type: Number,
      default: 0
    },
    viewType: {
      type: Object,
      default: () => ({})
    },
    fill: {
      type: String,
      default: ''
    },
    doorMirrorType: {
      type: String,
      default: ''
    },
    doorStrokeColor: {
      type: String,
      default: '#aeaeae'
    }
  },
  computed: {
    handleX1() {
      if (
        this.doorMirrorType !== 'mirrored' &&
        this.viewType.view_type === 'left'
      ) {
        return this.handleX - 14.49;
      }
      if (
        this.doorMirrorType === 'mirrored' &&
        this.viewType.view_type === 'left'
      ) {
        return this.handleX + 14.19;
      }
      if (
        this.doorMirrorType === 'mirrored' &&
        this.viewType.view_type === 'right'
      ) {
        return this.handleX - 14.49;
      }
      return this.handleX + 14.19;
    },
    handleY1() {
      return this.handleY - 25.5;
    },
    doorColor() {
      return this.doorSolidColor ? this.doorSolidColor : '#aeaeae';
    }
  }
};
</script>
